import React from "react"
import Layout from "../../components/layout-ages"
import { Link } from "gatsby"

export default () => (
  <Layout title="Toddlers">
    <h2>Toddlers</h2>
    <div className="clear-block">
      <div id="node-78" className="node">


        <div className="content clear-block">
          <p><img src="/images/toddler-230.jpg" className="float-right" width="230" height="286" alt=""/>The
            Toddler age group is so much fun to work with and their abilities are amazing! You will need
            “<Link to="/books/Beginning-Note-Reading">Note Reading for Children</Link>” and
            “<Link to="/flash-cards/Animal-Note-Standard-Note-Flash-Card-Set-Student-Size">Animal Note/Standard Note Flash
              Card Set-Grand Staff</Link>”.</p>
          <p>What will your Toddler gain from playing the piano?</p>
          <ol>
            <li>Eye hand coordination.</li>
            <li>Matching the animals, following a key down and striking it.</li>
            <li>Hearing the different sounds created by each key.</li>
            <li>Following directions and staying with task.</li>
            <li>Learning the rudiments of music.</li>
            <li>Finding real joy in playing the piano! Your Toddler will look forward to this special music time you
              will be sharing.
            </li>
          </ol>
          <p>What important things to think about while teaching your Toddler piano?</p>
          <ol>
            <li>Your Toddler will play with only one finger at first. For a young child this is fine. Once the first
              three notes are learned, tell your child that the Animals really like their own finger, so name the
              pointer finger, Cat, middle finger, Dog, and the ring finger is Elephant. The same with the left hand. At
              first most young children will pick out the needed finger, and tuck the other fingers into a fist to
              strike the key. As they relax, show them how you would play the notes and encourage them to do the same.
              In time they will learn to play the keys in a normal manner. The fingers will be flat as they start to
              play in this fashion, so tell them the finger like to “Walk like a Spider.” The thumb gets involved at the
              end of the book with Giraffe and Fish. Do not be discouraged if this takes time for your Toddler to
              master, they will succeed.
            </li>
            <li>Most Toddlers will move slowly through the first book. Their attention span is short. If at all possible
              work with them 5 to 10 minutes at least twice a week.
            </li>
            <li>Make the time fun with lots of praise whenever possible. Do not get impatient with them.</li>
            <li>Toddlers love variety, so use the Flash Cards for the notes they have learned. Let them arrange them to
              “Create their own songs.”
            </li>
          </ol>
          <p>Any nine-note keyboard can be used to teach your Toddler piano. If you have a piano, by all means use it,
            but if not, use a toy keyboard, a small electronic keyboard, or the keyboard on the <a
              href="https://itunes.apple.com/us/app/noteimals/id590029288?mt=8">Noteimals iPhone App</a> (available on
            iTunes). The Noteimals iPhone App is a great teaching tool. Your Toddler will love listening to, and playing
            the many available songs. </p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
